<template>
  <div v-if="loadingTypes.programLoading">
    <aom-skeleton-loader />
  </div>
  <div v-else>
    <b-card>
      <h2>{{ client.name }}</h2>
      <b-row align-v="center">
        <b-col sm="5">
          <v-select
            v-model="fromProgram"
            label="name"
            value="id"
            :options="programs"
            :selectable="
              (option) => (toProgram ? option.id !== toProgram.id : true)
            "
            placeholder="From program"
            class="w-100"
          />
        </b-col>
        <b-col sm="5">
          <v-select
            v-model="toProgram"
            label="name"
            value="id"
            :options="programs"
            :selectable="
              (option) => (fromProgram ? option.id !== fromProgram.id : true)
            "
            placeholder="To program"
            class="w-100"
          />
        </b-col>
        <b-col
          sm="2"
          class="text-right"
        >
          <b-button
            variant="primary"
            class="mr-1"
            :disabled="canTransfer || loadingTypes.transferring"
            @click="onTransferToProgram"
          >
            <b-spinner
              v-if="loadingTypes.transferring"
              small
            />
            <span v-if="loadingTypes.transferring">Transferring...</span>
            <span v-else>Transfer</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <!-- Participant table -->
      <b-col sm="6">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="6">
              <h4>Participants ({{ participantSelected.length }})</h4>
              <p class="font-small-3 mb-0">
                {{ participants.length }} Participants
              </p>
            </b-col>
            <b-col
              sm="6"
              class="text-right"
            >
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="selectAllParticipants"
              >
                Select all
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                @click="clearParticipantsSelected"
              >
                Clear selected
              </b-button>
            </b-col>
          </b-row>
          <b-table
            ref="participantTable"
            :items="participants"
            :fields="participantFields"
            :select-mode="'multi'"
            :busy="loadingTypes.participantLoading"
            responsive="sm"
            selectable
            @row-selected="onParticipantSelected"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <b-form-checkbox
                  :checked="true"
                  disabled
                  class="no-style-disabled"
                />
              </template>
              <template v-else>
                <b-form-checkbox
                  :checked="false"
                  disabled
                  class="no-style-disabled"
                />
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>

      <!-- Group table -->
      <b-col sm="6">
        <b-card>
          <b-row class="mb-2">
            <b-col sm="6">
              <h4>Groups ({{ groupSelected.length }})</h4>
              <p class="font-small-3 mb-0">
                {{ groups.length }} Groups
              </p>
            </b-col>
            <b-col
              sm="6"
              class="text-right"
            >
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                @click="selectAllGroups"
              >
                Select all
              </b-button>
              <b-button
                variant="primary"
                size="sm"
                @click="clearGroupsSelected"
              >
                Clear selected
              </b-button>
            </b-col>
          </b-row>

          <b-table
            ref="groupTable"
            :items="groups"
            :fields="groupFields"
            :select-mode="'multi'"
            :busy="loadingTypes.groupLoading"
            responsive="sm"
            selectable
            @row-selected="onGroupSelected"
          >
            <template #table-busy>
              <div class="text-center my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
                <strong>Loading...</strong>
              </div>
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <b-form-checkbox
                  :checked="true"
                  disabled
                  class="no-style-disabled"
                />
              </template>
              <template v-else>
                <b-form-checkbox
                  :checked="false"
                  disabled
                  class="no-style-disabled"
                />
              </template>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BButton, BTable, BSpinner, BFormCheckbox } from "bootstrap-vue";
import vSelect from "vue-select";
import { userRoles, userRolesDisplay } from "@/models/userRoles";
import { usersService, groupsService, programsService, clientsService } from "@services";
import AomSkeletonLoader from "@aom-core/AomSkeletonLoader.vue";
import { ClientClass } from '@models/clientClass';
import { makeSuccessToast, makeErrorToast } from "@/libs/utils";

export default {
  name: "LayoutTransferProgram",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BSpinner,
    BFormCheckbox,
    vSelect,
    AomSkeletonLoader
  },
  data() {
    return {
      client: new ClientClass(),
      programs: [],
      participantFields: ["selected", "full_name", "role"],
      groupFields: ["selected", "name", "type"],
      participants: [],
      groups: [],
      participantSelected: [],
      groupSelected: [],
      fromProgram: null,
      toProgram: null,
      loadingTypes: {
        programLoading: false,
        transferring: false,
        participantLoading: false,
        groupLoading: false,
      },
    };
  },
  computed: {
    clientIdParams() {
      return this.$route.params.clientId || null;
    },
    canTransfer() {
      return !(
        this.fromProgram &&
        this.toProgram &&
        (this.participantSelected.length || this.groupSelected.length)
      );
    },
  },
  watch: {
    fromProgram: {
      handler(n) {
        if (n && n.id) {
          this.fetchParticipants(n.id);
          this.fetchGroups(n.id);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    this.loadingTypes.programLoading = true;
    await Promise.all([
      this.fetchClient(),
      this.fetchClientPrograms()
    ]);
    this.loadingTypes.programLoading = false;
  },
  methods: {
    async fetchClient() {
      try {
        const response = await clientsService.getClientById(this.clientIdParams);
        const { data } = response;
        this.client = data;
      } catch (e) {
        this.$toast(makeErrorToast(`Unable to fetch Organisation ${this.clientIdParams}.`));
        this.$log(e);
      }
    },
    async fetchClientPrograms() {
      try {
        const response = await programsService.getClientPrograms(
          this.clientIdParams
        );
        this.programs = response.data.items;
      } catch (e) {
        this.$toast(makeErrorToast("Program list not loaded."));
        this.$log.error(e);
      }
    },
    handleDisplayRoles(roles) {
      const rolesOfParticipant = roles.reduce(function (filtered, option) {
        if (
          Number(option.role_id) === Number(userRoles.MENTOR) ||
          Number(option.role_id) === Number(userRoles.MENTEE)
        ) {
          var roleName = userRolesDisplay[option.role_id];
          filtered.push(roleName);
        }
        return filtered;
      }, []);
      return rolesOfParticipant.join(", ");
    },
    async onTransferToProgram() {
      try {
        this.loadingTypes.transferring = true;
        const response =
          await programsService.transferParticipantsFromAnotherProgram(
            this.toProgram.id,
            {
              from_program_id: this.fromProgram.id,
              users: this.participantSelected,
              groups: this.groupSelected,
            }
          );
        if (response) {
          this.$toast(makeSuccessToast("Transferred successfully."));
        }
      } catch (e) {
        this.$toast(makeErrorToast("Something went wrong!"));
        this.$log.error(e);
      } finally {
        this.loadingTypes.transferring = false;
      }
    },
    // Participants
    async fetchParticipants(programId) {
      try {
        this.loadingTypes.participantLoading = true;
        const response = await usersService.getListParticipantsByProgram(
          programId
        );
        this.participants = response.data.items.map(i => ({
          ...i,
          role: this.handleDisplayRoles(i.user_roles),
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Participants list not loaded."));
      } finally {
        this.loadingTypes.participantLoading = false;
      }
    },
    onParticipantSelected(items) {
      this.participantSelected = items;
    },
    selectAllParticipants() {
      this.$refs.participantTable.selectAllRows();
    },
    clearParticipantsSelected() {
      this.$refs.participantTable.clearSelected();
    },
    // Groups
    async fetchGroups(programId) {
      try {
        this.loadingTypes.groupLoading = true;
        const response = await groupsService.getList(programId);
        this.groups = response.data.items.map(i => ({
          ...i,
          type: i.type.name,
        }));
      } catch (e) {
        this.$log.error(e);
        this.$toast(makeErrorToast("Groups list not loaded."));
      } finally {
        this.loadingTypes.groupLoading = false;
      }
    },
    onGroupSelected(items) {
      this.groupSelected = items;
    },
    selectAllGroups() {
      this.$refs.groupTable.selectAllRows();
    },
    clearGroupsSelected() {
      this.$refs.groupTable.clearSelected();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .no-style-disabled {
  .custom-control-label {
    &::before {
      border: 1px solid !important;
      background-color: transparent;
    }
    &::after {
      cursor: pointer;
    }
  }
}
</style>
