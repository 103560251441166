export class ClientClass {
  constructor ({
    id = undefined,
    name = undefined,
    path = undefined,
    indexed = false,
    // eslint-disable-next-line camelcase
    created_at = undefined,
    status = undefined,
    // eslint-disable-next-line camelcase
    active_programs_count = 0,
    // eslint-disable-next-line camelcase
    programs_count = 0
  } = {}) {
    this.id = id;
    this.name = name;
    this.path = path;
    this.indexed = indexed;
    // eslint-disable-next-line camelcase
    this.created_at = created_at;
    status = status;
    // eslint-disable-next-line camelcase
    this.active_programs_count = active_programs_count;
    // eslint-disable-next-line camelcase
    this.programs_count = programs_count;
  }
}